.header {
  display: flex;
  font-family: "Raleway", sans-serif;
  align-items: center;
  padding: 0px 10px;
  margin: 10px;
  font-weight: 700;
  font-size: 50px;
  width: 100%;
  box-sizing: border-box;
}

$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .header {
    justify-content: center;
    padding: unset;
  }
}
