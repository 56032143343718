@import '../../styles/styles.scss';
.html,
.body {
  overflow: hidden;
  margin: 0;
}
// ::-webkit-scrollbar {
//   width: 0;
//   background: transparent;
// }
@keyframes parallax {
  0% {
    background-position: 0vw 50%, 0vw 50%;
  }
  50% {
    background-position: 100000vw 50%, 30000vw 50%;
  }
  100% {
    background-position: 0vw 50%, 0vw 50%;
  }
}
.backgroundBack {
  height: 100vh;
  width: 100vw;
  background-color: #244b7e;
  background-image: url('../../assets/Middleground.png'), url('../../assets/Background.png');
  background-repeat: repeat-x, repeat-x;
  background-position: center, center;
  background-size: cover, cover;
  animation: 15000s parallax infinite linear;
}
.backgroundBlurred {
  height: 100%;
  /* max-height: 600px; */
  width: 100vw;
  background-color: #244b7e;
  background-image: url('../../assets/MiddlegroundBlurred.png'), url('../../assets/BackgroundBlurred.png');
  background-repeat: repeat-x, repeat-x;
  background-position: center, center;
  background-size: cover, cover;
  height: 100vh;
  left: 0;
  animation: 15000s parallax infinite linear;
}
@keyframes animateColor {
  0% {
    filter: hue-rotate(0deg);
  }
  33% {
    filter: hue-rotate(60deg);
  }
  66% {
    filter: hue-rotate(-60deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
.name {
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 42%);
  image-rendering: pixelated;

  width: 45vw;
  min-width: 275px;

  -webkit-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  -moz-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
}

@keyframes animateEnter {
  0% {
    visibility: visible;
  }
  33% {
    visibility: hidden;
  }
  66% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.musicPlayerControls {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  -webkit-animation: fadeIn 1s linear forwards ease;
  -moz-animation: fadeIn 1s linear forwards;
  animation: fadeIn 1s linear forwards;
}

.musicPlayerControlsInvisible {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  -webkit-animation: fadeOut 1s linear forwards;
  -moz-animation: fadeOut 1s linear forwards;
  animation: fadeOut 1s linear forwards;
}

.musicPlayerControlsPlay {
  z-index: 2;
  min-height: 35px;
  min-width: 35px;
  width: 2.5vw;
  height: 2.5vw;

  border: none;
  outline: none;
  background: url('../../assets/PlayY.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;

  -webkit-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  -moz-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
}
.musicPlayerControlsPlay:hover {
  cursor: pointer;
  transform: scale(1.1);
  background: url('../../assets/PlayB.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;
}

.musicPlayerControlsPause {
  z-index: 2;
  min-height: 35px;
  min-width: 35px;
  width: 2.5vw;
  height: 2.5vw;

  border: none;
  outline: none;
  background: url('../../assets/PauseY.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;

  -webkit-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  -moz-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
}
.musicPlayerControlsPause:hover {
  cursor: pointer;
  transform: scale(1.1);
  background: url('../../assets/PauseB.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;
}

.musicPlayerControlsNext {
  z-index: 2;
  min-height: 17px;
  min-width: 17px;
  width: 1.2vw;
  height: 1.2vw;
  margin-left: 3px;
  border: none;
  outline: none;
  background: url('../../assets/NextB.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;

  -webkit-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  -moz-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
}
.musicPlayerControlsNext:hover {
  cursor: pointer;
  transform: scale(1.1);
  background: url('../../assets/NextY.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;
}

.musicPlayerControlsLast {
  z-index: 2;
  min-height: 17px;
  min-width: 17px;
  width: 1.2vw;
  height: 1.2vw;
  margin-left: 2vh;
  margin-right: 3px;
  border: none;
  outline: none;
  background: url('../../assets/LastB.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;

  -webkit-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  -moz-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
}
.musicPlayerControlsLast:hover {
  cursor: pointer;
  transform: scale(1.1);
  background: url('../../assets/LastY.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;
}

.settings {
  z-index: 2;
  min-height: 55px;
  min-width: 55px;
  height: auto;
  width: 4vw;
  height: 4vw;
  margin-top: 1vh;
  margin-right: 2vh;
  margin-left: auto;
  border: none;
  outline: none;
  background: url('../../assets/SettingsY.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;

  -webkit-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  -moz-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
}

.settings:hover {
  cursor: pointer;
  transform: scale(1.1);
  background: url('../../assets/SettingsB.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;
}

.settingsOn {
  z-index: 2;
  position: absolute;
  text-align: center;
  image-rendering: pixelated;
  max-height: 110%;
  min-height: auto;
  max-width: 100vw;
  width: auto;
  margin: auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: pixelated;
  transition: 2s ease-in-out;
}
.settingsOff {
  z-index: 2;
  position: absolute;
  text-align: center;
  image-rendering: pixelated;
  max-height: 110%;
  min-height: auto;
  max-width: 100vw;
  width: auto;
  margin: auto;
  display: block;
  position: absolute;
  top: 250%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: pixelated;
  transition: 2s;
}

.settingsDone {
  z-index: 3;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, -50%);
  display: inline;
  margin-right: 35%;
  margin-left: auto;
  border: none;
  outline: none;
  min-height: 30px;
  min-width: 85px;
  width: 15vw;
  height: 4vw;

  -webkit-animation: fadeInPause 3s linear forwards;
  -moz-animation: fadeInPause 3s linear forwards;
  animation: fadeInPause 3s linear forwards;
  background: url('../../assets/DoneY.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;
}

.settingsDoneOff {
  visibility: hidden;
}

.settingsDone:hover {
  cursor: pointer;
  transform: scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  background: url('../../assets/DoneB.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;
}

.settingsDevpost {
  z-index: 2;
  border: none;
  outline: none;
  height: 8vw;
  width: 8vw;
  min-height: 80px;
  min-width: 80px;
  -webkit-animation: fadeInPause 3s linear forwards;
  -moz-animation: fadeInPause 3s linear forwards;
  animation: fadeInPause 3s linear forwards;
  background: url('../../assets/Devpost.png') no-repeat;
  background-size: 145%;
  image-rendering: pixelated;
  background-position: 50% 8%;
  transform: translate(0, 5vh);
}

.settingsDevpostOff {
  visibility: hidden;
}

.settingsDevpost:hover {
  cursor: pointer;
  transform: translate(0, 5vh) scale(1.2);
  -webkit-animation: fadeInPause 3s linear forwards;
  -moz-animation: fadeInPause 3s linear forwards;
  animation: fadeInPause 3s linear forwards;
  background: url('../../assets/Devpost.png') no-repeat;
  background-size: 145%;
  image-rendering: pixelated;
  background-position: 50% 8%;
}

.settingsEmail {
  z-index: 2;
  border: none;
  outline: none;
  height: 9vw;
  width: 9vw;
  min-height: 90px;
  min-width: 90px;
  -webkit-animation: fadeInPause 3.2s linear forwards;
  -moz-animation: fadeInPause 3.2s linear forwards;
  animation: fadeInPause 3.2s linear forwards;
  background: url('../../assets/Email.png') no-repeat;
  background-size: 200%;
  image-rendering: pixelated;
  background-position: 50% 27%;
  transform: translate(0, 5vh);
}

.settingsEmailOff {
  visibility: hidden;
}

.settingsEmail:hover {
  cursor: pointer;
  transform: translate(0, 5vh) scale(1.2);
  -webkit-animation: fadeInPause 3s linear forwards;
  -moz-animation: fadeInPause 3s linear forwards;
  animation: fadeInPause 3s linear forwards;
  background: url('../../assets/Email.png') no-repeat;
  background-size: 200%;
  image-rendering: pixelated;
  background-position: 50% 27%;
}

.settingsGithub {
  z-index: 2;
  border: none;
  outline: none;
  height: 8vw;
  width: 8vw;
  min-height: 80px;
  min-width: 80px;
  -webkit-animation: fadeInPause 3.4s linear forwards;
  -moz-animation: fadeInPause 3.4s linear forwards;
  animation: fadeInPause 3.4s linear forwards;
  background: url('../../assets/Github.png') no-repeat;
  background-size: 130%;
  image-rendering: pixelated;
  background-position: 50% 8%;
}

.settingsGithubOff {
  visibility: hidden;
}

.settingsGithub:hover {
  cursor: pointer;
  transform: scale(1.2);
  -webkit-animation: fadeInPause 3s linear forwards;
  -moz-animation: fadeInPause 3s linear forwards;
  animation: fadeInPause 3s linear forwards;
  background: url('../../assets/Github.png') no-repeat;
  background-size: 130%;
  image-rendering: pixelated;
  background-position: 50% 8%;
}

.settingsLinkedIn {
  z-index: 2;
  border: none;
  outline: none;
  height: 8vw;
  width: 8vw;
  min-height: 80px;
  min-width: 80px;
  -webkit-animation: fadeInPause 3.6s linear forwards;
  -moz-animation: fadeInPause 3.6s linear forwards;
  animation: fadeInPause 3.6s linear forwards;
  background: url('../../assets/LinkedIn.png') no-repeat;
  background-size: 145%;
  image-rendering: pixelated;
  background-position: 50% 8%;
  transform: translate(0, -5vh);
}

.settingsLinkedInOff {
  visibility: hidden;
}

.settingsLinkedIn:hover {
  cursor: pointer;
  transform: translate(0, -5vh) scale(1.2);
  -webkit-animation: fadeInPause 3s linear forwards;
  -moz-animation: fadeInPause 3s linear forwards;
  animation: fadeInPause 3s linear forwards;
  background: url('../../assets/LinkedIn.png') no-repeat;
  background-size: 145%;
  image-rendering: pixelated;
  background-position: 50% 8%;
}

.settingsVSCO {
  z-index: 2;
  border: none;
  outline: none;
  height: 8vw;
  width: 8vw;
  min-height: 80px;
  min-width: 80px;
  -webkit-animation: fadeInPause 3.8s linear forwards;
  -moz-animation: fadeInPause 3.8s linear forwards;
  animation: fadeInPause 3.8s linear forwards;
  background: url('../../assets/VSCO.png') no-repeat;
  background-size: 145%;
  image-rendering: pixelated;
  background-position: 50% 8%;
}

.settingsVSCOOff {
  visibility: hidden;
}

.settingsVSCO:hover {
  cursor: pointer;
  transform: scale(1.2);
  -webkit-animation: fadeInPause 3s linear forwards;
  -moz-animation: fadeInPause 3s linear forwards;
  animation: fadeInPause 3s linear forwards;
  background: url('../../assets/VSCO.png') no-repeat;
  background-size: 145%;
  image-rendering: pixelated;
  background-position: 50% 8%;
}

.settingsYoutube {
  z-index: 2;
  border: none;
  outline: none;
  height: 8vw;
  width: 8vw;
  min-height: 80px;
  min-width: 80px;
  -webkit-animation: fadeInPause 3.8s linear forwards;
  -moz-animation: fadeInPause 3.8s linear forwards;
  animation: fadeInPause 3.8s linear forwards;
  background: url('../../assets/Youtube.png') no-repeat;
  background-size: 145%;
  image-rendering: pixelated;
  background-position: 50% 8%;
  transform: translate(0, -5vh) scale(1.1);
}

.settingsYoutubeOff {
  visibility: hidden;
}

.settingsYoutube:hover {
  cursor: pointer;
  transform: translate(0, -5vh) scale(1.25);
  -webkit-animation: fadeInPause 3s linear forwards;
  -moz-animation: fadeInPause 3s linear forwards;
  animation: fadeInPause 3s linear forwards;
  background: url('../../assets/Youtube.png') no-repeat;
  background-size: 145%;
  image-rendering: pixelated;
  background-position: 50% 8%;
}
.bottomButtons {
  z-index: 2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, -50%);
  -webkit-animation: fadeIn 1s linear forwards ease;
  -moz-animation: fadeIn 1s linear forwards;
  animation: fadeIn 1s linear forwards;
}
.bottomButtonsInvisible {
  z-index: 2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, -50%);
  -webkit-animation: fadeOut 1s linear forwards;
  -moz-animation: fadeOut 1s linear forwards;
  animation: fadeOut 1s linear forwards;
}

.rules {
  display: inline;
  margin-right: 35%;
  margin-left: auto;
  border: none;
  outline: none;
  min-height: 30px;
  min-width: 85px;
  width: 15vw;
  height: 4vw;

  background: url('../../assets/RulesY.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;
  -webkit-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  -moz-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
}
.rules:hover {
  cursor: pointer;
  transform: scale(1.1);
  background: url('../../assets/RulesB.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;
}

.rulesOn {
  z-index: 2;
  position: absolute;
  text-align: center;
  image-rendering: pixelated;
  max-height: 130%;
  min-height: auto;
  max-width: 100vw;
  width: auto;
  margin: auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: pixelated;
  transition: 2s ease-in-out;
}
.rulesOff {
  z-index: 2;
  position: absolute;
  text-align: center;
  image-rendering: pixelated;
  max-height: 130%;
  min-height: auto;
  max-width: 100vw;
  width: auto;
  margin: auto;
  display: block;
  position: absolute;
  top: 250%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: pixelated;
  transition: 2s;
}

.rulesDone {
  z-index: 3;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, -50%);
  display: inline;
  margin-right: 35%;
  margin-left: auto;
  border: none;
  outline: none;
  min-height: 30px;
  min-width: 85px;
  width: 15vw;
  height: 4vw;

  -webkit-animation: fadeInPause 3s linear forwards;
  -moz-animation: fadeInPause 3s linear forwards;
  animation: fadeInPause 3s linear forwards;
  background: url('../../assets/DoneY.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;
}

.rulesDoneOff {
  visibility: hidden;
}

.rulesDone:hover {
  cursor: pointer;
  background: url('../../assets/DoneB.png') no-repeat;
  background-size: contain;
  transform: translate(-50%, -50%) scale(1.1);
  background-position: center;
  image-rendering: pixelated;
}

.start {
  display: inline;
  margin-left: auto;
  border: none;
  outline: none;
  min-height: 30px;
  min-width: 85px;
  width: 15vw;
  height: 4vw;

  background: url('../../assets/StartY.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;
  -webkit-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  -moz-animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
  animation: animateColor 40s linear infinite, fadeIn 1s linear forwards;
}
.start:hover {
  cursor: pointer;
  transform: scale(1.1);
  background: url('../../assets/StartB.png') no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: pixelated;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInPause {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.contactGrid {
  z-index: 2;
  position: absolute;
  text-align: center;
  transition: 2s ease-in-out;
  align-items: center;
  justify-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -47%);
  max-width: 100vw;
  max-height: 130%;
  min-height: auto;
  display: inline-grid;
  width: auto;
  margin: auto;
  grid-template-columns: repeat(2, 15vw);
  grid-template-rows: repeat(3, 17vh);
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  -webkit-animation: fadeInPause 3s linear forwards;
  -moz-animation: fadeInPause 3s linear forwards;
  animation: fadeInPause 3s linear forwards;
  flex-wrap: wrap;
}
.other {
  z-index: 2;

  transition: 2s ease-in-out;
}
