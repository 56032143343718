.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 90%;
  margin: 0 auto;

  button {
    background: none;
    border: none;
    border: 2px solid;
    padding: 12px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 30px;

    &:hover {
      opacity: 0.75;
      transition: opacity 0.5s;
    }
  }
}
