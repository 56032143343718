.loader {
  height: 100%;
  padding-top: 5%;
  box-sizing: border-box;

  .header {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .content {
    margin: 3% auto;
    display: flex;
    width: 90%;
    justify-content: center;
  }

  .loader-btn {
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    overflow: hidden;
  }
}

$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .loader {
    .description {
      width: 95%;
      margin: 0 auto;
    }
  }
}
