@import "../../../../styles/styles.scss";

.app-container {
  font-family: "Raleway", sans-serif;
  width: 70%;
  margin: 0 auto;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  box-sizing: border-box;

  .picture-container {
    padding: 30px;
    box-sizing: border-box;
    animation: slideIn 0.5s;
  }

  .title {
    text-align: center;
    margin: 0;
  }

  .gallery-container {
    width: 70%;
    margin: 0 auto;
    height: 500px;
    display: flex;
    align-items: center;
    position: relative;
    div,
    iframe {
      width: 90%;
      height: 80%;
      margin: 0 auto;
      margin-top: 30px;
    }
    iframe {
      display: block;
      border-style: none;
    }

    .back-btn,
    .next-btn {
      left: 0;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      border-radius: 50%;
      padding: 10px;
      height: 50px;
      width: 50px;

      .image-loading {
        position: absolute;
        bottom: 45px;
      }

      .image-popup {
        position: absolute;
        width: 270px;
        left: 0;
        bottom: 50px;
        height: 150px;
        box-sizing: border-box;
        animation: popup 0.5s forwards;

        img,
        video {
          width: 100%;
          height: 100%;
          border-radius: 12px;
          animation: popup 0.5s forwards;
          object-fit: cover;
        }
      }
    }

    .next-btn {
      right: 0;
      left: unset;

      .image-popup {
        right: 0;
        bottom: 50px;
        left: unset;
      }
    }

    img,
    video {
      height: 90%;
      width: 80%;
      margin: 0 auto;
      object-fit: cover;
      object-position: center;
    }
  }

  .buttons {
    width: 75%;
    display: flex;  
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 20px;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
  }

  .custom-btn {
    background: $blue;
    color: $white;
    min-width: fit-content;
    padding: 5px 20px;
    width: 185px;
    min-height: 40px;
    border: 1px solid $white;
    outline: none;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  ::-webkit-calendar-picker-indicator {
    cursor: pointer;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path fill="%23ffffff" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  }
  .description {
    padding: 25px;
    animation: slideInX 1s;
    font-size: 18px;
    line-height: 30px;
  }
}

#footer .container .row {
  padding-bottom: 8px;
}

@keyframes popup {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInX {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .app-container {
    width: 100%;

    h1 {
      font-size: 20px;
    }

    .gallery-container {
      height: 440px;
    }

    .custom-btn {
      width: 130px;
      height: 40px;
      padding: 0 10px;
      font-size: 14px;
    }

    .description {
      padding: 25px;
      width: 90%;
      margin: 0 auto;
      text-align: justify;
      word-break: break-word;
    }
  }
}
