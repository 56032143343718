a:-webkit-any-link {
  color: $highlight;
  text-decoration: none;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
